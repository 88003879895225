import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	Select,
	MenuItem,
	ListItemText,
	Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// cmp
import DrawerDialog from "../DrawerDialog";
import RowEntry from "../row-entry";
// services
import Constants from "../../services/constants";
import { numberFormatter0Digits } from "../../services/l10n";
import { getNumberRange } from "../../services/utils";
// types
import type { TFunction } from "i18next";
import type { WithTranslation } from "react-i18next";
import type { SelectChangeEvent } from "@mui/material/Select";

const PORTION_MIN = 1;
const PORTION_MAX = 10;
const PORTION_STEP = 1;

const PORTION_VALUES = getNumberRange(PORTION_MIN, PORTION_MAX, PORTION_STEP);

type Props = Readonly<WithTranslation & {
	open: boolean;
	isScheduler: boolean; // optional
	value: number; // optional
	portionWeight: number;
	onFoodNumberChange: (value: number) => void;
	onClose: () => void;
}>;

type State = {
	showDialog: boolean;
	saving: boolean;
	value: number;
};

class PetFeeder extends Component<Props, State> {

	#t: TFunction = this.props.t;

	public static defaultProps = {
		isScheduler: false,
		value: 1,
	} satisfies Partial<Props>;

	constructor(props: Props) {
		super(props);

		this.state = {
			showDialog: props.open,
			saving: false,
			value: props.value,
		};

		this.closeDialog = this.closeDialog.bind(this);
		this.handleFeedClick = this.handleFeedClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	override componentDidUpdate(prevProps: Props) {
		if (prevProps.open !== this.props.open || prevProps.value !== this.props.value) {
			this.setState({
				showDialog: this.props.open,
				saving: false,
				value: this.props.value,
			});
		}
	}

	closeDialog() {
		this.setState({
			showDialog: false,
			value: 1,
		});
		this.props.onClose();
	}

	handleChange(event: SelectChangeEvent<number>) {
		this.setState({
			value: event.target.value as number,
		});
	}

	handleFeedClick() {
		this.setState({
			saving: true,
		}, () => {
			this.props.onFoodNumberChange(this.state.value);
		});
	}

	override render() {
		return (
			<DrawerDialog
				id="dlg-pet-feeder"
				title={this.#t(this.props.isScheduler ? "clusters.DFF96.feedPortions" : "clusters.DFF96.manualFeed")}
				open={this.state.showDialog}
				onClose={this.closeDialog}
				drawerActions={
					<LoadingButton
						className="btn-drawer-action-save"
						variant="contained"
						disabled={!this.state.value}
						loading={this.state.saving && !this.props.isScheduler}
						onClick={this.handleFeedClick}
						sx={{ minWidth: "40%", maxWidth: "320px" }}
					>
						{this.#t(this.props.isScheduler ? "dialog.save" : "clusters.DFF96.feed")}
					</LoadingButton>
				}
				dialogActions={
					<>
						<Button className="btn-dlg-action-cancel" color="inherit" onClick={this.closeDialog}>{this.#t("dialog.cancel")}</Button>
						<LoadingButton
							className="btn-dlg-action-save"
							variant="contained"
							disableElevation={true}
							disabled={!this.state.value}
							loading={this.state.saving && !this.props.isScheduler}
							onClick={this.handleFeedClick}
						>
							{this.#t(this.props.isScheduler ? "dialog.save" : "clusters.DFF96.feed")}
						</LoadingButton>
					</>
				}
			>
				<RowEntry
					title={this.#t("clusters.DFF96.portions")}
					subTitle={this.#t("clusters.DFF96.amount", { VALUE: this.state.value * this.props.portionWeight, UNIT: Constants.Units.Gram })}
					styleRight={{ marginTop: "-12px" }}
				>
					<Select
						fullWidth={true}
						value={this.state.value}
						onChange={this.handleChange}
					>
						{PORTION_VALUES.map((portionValue) => (
							<MenuItem className="select-feed-portion" key={portionValue} value={portionValue}>
								<ListItemText primary={numberFormatter0Digits(portionValue)} />
							</MenuItem>
						))}
					</Select>
				</RowEntry>
			</DrawerDialog>
		);
	}

}

export default withTranslation()(PetFeeder);
