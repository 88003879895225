import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
// services
import { getNumberRange, getTimePartsFromDuration } from "../../services/utils";
// types
import type { TFunction } from "i18next";
import type { WithTranslation } from "react-i18next";
import type { SelectChangeEvent } from "@mui/material/Select";

type Props = Readonly<WithTranslation & {
	title: string;
	open: boolean;
	value: number;
	min: number; // optional
	max: number; // optional
	onChange: (event: null, value: number) => void;
	onCancel: () => void;
}>;

type State = {
	showDialog: boolean;
	hour: number;
	minute: number;
	second: number;
	minutes: Array<number>;
	seconds: Array<number>;
};

class DelayDurationComponent extends Component<Props, State> {

	#t: TFunction = this.props.t;

	public static defaultProps = {
		min: 1,
		max: 60 * 60,
	} satisfies Partial<Props>;

	constructor(props: Props) {
		super(props);

		const { hours: hour, minutes: minute, seconds: second } = getTimePartsFromDuration(props.value * 1000);
		const { minutes, seconds } = DelayDurationComponent.getMinutesSecondsFromMinMax(props.min, props.max);

		this.state = {
			showDialog: props.open,
			hour: hour,
			minute: minute,
			second: second,
			minutes: minutes,
			seconds: seconds,
		};

		this.closeDialog = this.closeDialog.bind(this);
		this.handleOkClick = this.handleOkClick.bind(this);
		this.handleMinuteChange = this.handleMinuteChange.bind(this);
		this.handleSecondChange = this.handleSecondChange.bind(this);
	}

	override componentDidUpdate(prevProps: Props) {
		if (prevProps.open !== this.props.open) {
			this.setState({
				showDialog: this.props.open,
			});
		}
		if (prevProps.value !== this.props.value) {
			const { hours: hour, minutes: minute, seconds: second } = getTimePartsFromDuration(this.props.value * 1000);

			this.setState({
				hour: hour,
				minute: minute,
				second: second,
			});
		}
		if (prevProps.min !== this.props.min || prevProps.max !== this.props.max) {
			const { minutes, seconds } = DelayDurationComponent.getMinutesSecondsFromMinMax(this.props.min, this.props.max);

			this.setState({
				minutes: minutes,
				seconds: seconds,
			});
		}
	}

	static getMinutesSecondsFromMinMax(min: number, max: number) {
		return {
			minutes: getNumberRange(Math.floor(min / 60), Math.floor(max / 60)),
			seconds: getNumberRange((max < 60) ? Math.max(0, min) : 0, (max < 60) ? Math.min(59, max) : 59),
		};
	}

	closeDialog() {
		const { hours: hour, minutes: minute, seconds: second } = getTimePartsFromDuration(this.props.value * 1000);

		this.setState({
			showDialog: false,
			hour: hour,
			minute: minute,
			second: second,
		});
		this.props.onCancel();
	}

	getSecondFromMinuteInRange(minute: number, second: number) {
		if (minute * 60 + second > this.props.max) {
			return (this.props.max - minute * 60) % 60;
		}
		if (minute * 60 + second < this.props.min) {
			return this.props.min;
		}
		return second;
	}

	handleMinuteChange(event: SelectChangeEvent<number>) {
		this.setState({ hour: 0 });
		this.setState((prevState) => ({
			minute: event.target.value as number,
			second: this.getSecondFromMinuteInRange(event.target.value as number, prevState.second),
		}));
	}

	handleSecondChange(event: SelectChangeEvent<number>) {
		this.setState({
			second: event.target.value as number,
		});
	}

	handleOkClick() {
		this.setState({
			showDialog: false,
		});
		const value = this.state.hour * 3600 + this.state.minute * 60 + this.state.second;
		this.props.onChange(null, value);
	}

	isInValidValue(hour: number, minute: number, second: number) {
		const duration = hour * 3600 + minute * 60 + second;
		return duration < this.props.min || duration > this.props.max;
	}

	override render() {
		return (
			<DrawerDialog
				id="dlg-delay-duration-component"
				title={this.props.title}
				open={this.state.showDialog}
				onClose={this.closeDialog}
				drawerActions={
					<Button
						className="btn-drawer-action-ok"
						variant="contained"
						disabled={this.isInValidValue(this.state.hour, this.state.minute, this.state.second)}
						onClick={this.handleOkClick}
						sx={{ minWidth: "40%", maxWidth: "320px" }}
					>
						{this.#t("dialog.ok")}
					</Button>
				}
				dialogActions={
					<>
						<Button className="btn-dlg-action-cancel" color="inherit" onClick={this.closeDialog}>{this.#t("dialog.cancel")}</Button>
						<Button
							className="btn-dlg-action-ok"
							variant="contained"
							disableElevation={true}
							disabled={this.isInValidValue(this.state.hour, this.state.minute, this.state.second)}
							onClick={this.handleOkClick}
						>
							{this.#t("dialog.ok")}
						</Button>
					</>
				}
			>
				<div style={{display: "inline-flex", justifyContent: "center", width: "100%"}}>
					<FormControl sx={{ width: "40%", paddingRight: "5px" }}>
						<InputLabel shrink={true}>
							{this.#t("general.time.minutes_other")}
						</InputLabel>
						<Select
							fullWidth={true}
							value={this.state.hour * 60 || this.state.minute}
							onChange={this.handleMinuteChange}
						>
							{this.state.minutes.map((minute) => (
								<MenuItem className="select-delay-duration-minute" key={minute} value={minute}>
									{minute}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: "40%" }}>
						<InputLabel shrink={true}>
							{this.#t("general.time.seconds_other")}
						</InputLabel>
						<Select
							fullWidth={true}
							value={this.state.second}
							onChange={this.handleSecondChange}
						>
							{this.state.seconds.map((second) => (
								<MenuItem
									className="select-delay-duration-second"
									key={second}
									value={second}
									disabled={this.isInValidValue(this.state.hour, this.state.minute, second)}
								>
									{second}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DrawerDialog>
		);
	}

}

export default withTranslation()(DelayDurationComponent);
