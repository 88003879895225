import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ListItemText,
	Button,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
// services
import { numberFormatter0Digits } from "../../services/l10n";
import { getNumberRange } from "../../services/utils";
// types
import type { TFunction } from "i18next";
import type { WithTranslation } from "react-i18next";
import type { SelectChangeEvent } from "@mui/material/Select";

const FAN_SPEED_MIN = 1;
const FAN_SPEED_STEP = 1;

type Props = Readonly<WithTranslation & {
	open: boolean;
	value: number;
	maxSpeed: number;
	onChange: (fanSpeed: number) => void;
	onCancel: () => void;
}>;

type State = {
	showDialog: boolean;
	value: number;
};

class FanSpeedComponent extends Component<Props, State> {

	#t: TFunction = this.props.t;

	constructor(props: Props) {
		super(props);

		this.state = {
			showDialog: props.open,
			value: props.value,
		};

		this.closeDialog = this.closeDialog.bind(this);
		this.handleOkClick = this.handleOkClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	override componentDidUpdate(prevProps: Props) {
		if (prevProps.open !== this.props.open) {
			this.setState({
				showDialog: this.props.open,
			});
		}
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	closeDialog() {
		this.setState({
			showDialog: false,
			value: this.props.value,
		});
		this.props.onCancel();
	}

	handleChange(event: SelectChangeEvent<number>) {
		this.setState({
			value: event.target.value as number,
		});
	}

	handleOkClick() {
		this.setState({
			showDialog: false,
		});
		this.props.onChange(this.state.value);
	}

	override render() {
		return (
			<DrawerDialog
				id="dlg-fan-speed"
				title={this.#t("clusters.DFF99.fanSpeedDialogTitle")}
				open={this.state.showDialog}
				onClose={this.closeDialog}
				drawerActions={
					<Button
						className="btn-drawer-action-ok"
						variant="contained"
						onClick={this.handleOkClick}
						sx={{ minWidth: "40%", maxWidth: "320px" }}
					>
						{this.#t("dialog.ok")}
					</Button>
				}
				dialogActions={
					<>
						<Button className="btn-dlg-action-cancel" color="inherit" onClick={this.closeDialog}>{this.#t("dialog.cancel")}</Button>
						<Button
							className="btn-dlg-action-ok"
							variant="contained"
							disableElevation={true}
							onClick={this.handleOkClick}
						>
							{this.#t("dialog.ok")}
						</Button>
					</>
				}
			>
				<FormControl sx={{ width: "50%" }}>
					<InputLabel shrink={true}>
						{this.#t("clusters.DFF99.titleFanSpeed")}
					</InputLabel>
					<Select
						fullWidth={true}
						value={this.state.value}
						onChange={this.handleChange}
					>
						{getNumberRange(FAN_SPEED_MIN, this.props.maxSpeed, FAN_SPEED_STEP).map((value) => (
							<MenuItem className="select-fan-speed" key={value} value={value}>
								<ListItemText primary={numberFormatter0Digits(value)} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DrawerDialog>
		);
	}

}

export default withTranslation()(FanSpeedComponent);
